<template>
  <div>
    <el-dialog :visible.sync="sta.show">
      <span slot="title">新增单据</span>
      <div class="row">
        <div class="col-md-12">
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>仓库名称</b></label>
                <!-- <div>
                  <el-input type="text" v-model="model.STOCK.STORAGE_NAME" style="width: 90%;" disabled />
                </div> -->
                <el-select v-model="model.STORAGE_ID" placeholder="请选择仓库" @change="changeStorage">
                  <el-option :label="it.STORAGE_NAME" :value="it.ID" v-for="it in store" :key="it.ID" />
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>分类</b></label><br />
                <el-cascader :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" @change="getStock"
                  v-model="cat.val" :options="cat.ls" placeholder="请选择分类" />
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>物品</b></label><br />
                <el-select v-model="model.ST_ID" placeholder="请选择物品" @change="stockChange" filterable
                  :filter-method="search">
                  <el-option v-for="item in ls_stock" :key="item.ID" :label="item.NAME" :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>

          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>发生日期</b></label><br />
                <el-date-picker v-model="model.EVENT_DT" type="date" placeholder="选择日期" />
              </div>
            </el-col>
            <!-- <el-col :span="8">
              <div class="form-group">
                <label><b>仓库名称</b></label>
                <div>
                  <el-input type="text" v-model="model.STOCK.STORAGE_NAME" style="width: 90%;" disabled />
                </div>
              </div>
            </el-col> -->
            <el-col :span="8">
              <div class="form-group">
                <template v-if="model.TYPE == 1">
                  <label><b>出库人</b></label><br />
                </template>
                <template v-if="model.TYPE == 2">
                  <label><b>入库人</b></label><br />
                </template>
                <template v-if="model.TYPE == 3">
                  <label><b>库管</b></label><br />
                </template>
                <el-select v-if="model.TYPE == 3" multiple v-model="model.STOCK.MAN_USERS_LIST" disabled>
                  <el-option v-for="item in ls_teacher" :key="item.ID" :label="item.NAME" :value="item.ID">
                  </el-option>
                </el-select>
                <el-select v-else v-model="InOut_Stock_User" value-key="ID" filterable>
                  <el-option v-for="item in ls_teacher" :key="item.ID" :label="item.NAME" :value="item">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <template v-if="model.TYPE == 3"><label><b>盘存人</b></label><br /></template>
                <template v-else><label><b>经手人</b></label><br /></template>
                <el-select v-model="model.HANDLE_NAME" filterable>
                  <el-option v-for="item in ls_teacher" :key="item.ID" :label="item.NAME" :value="item.NAME">
                  </el-option>
                </el-select>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8">
              <div class="form-group">
                <label><b>数量</b><span style="color: red" v-if="model.TYPE == 3">
                    (正数盘盈，负数盘亏)</span></label>
                <div>
                  <el-input-number v-model="model.CNT" :step="1" @change="changePrice"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8">
              <div class="form-group">
                <label><b>库存量</b></label>
                <div>
                  <el-input v-model="model.STOCK.BALANCE" readonly style="width: 60%;">
                    <template slot="prepend"><i class="el-icon-coin" /></template>
                  </el-input>
                </div>
              </div>
            </el-col>
            <el-col v-if="model.TYPE == 1" :span="8">
              <div class="form-group">
                <label><b>位置</b></label>
                <div>
                  <el-cascader v-model="loc.val" :options="loc.ls" placeholder="请选择位置" :disabled="!!model.CLASS_ID"
                    clearable :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN' }" />
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row v-if="model.TYPE == 2">

            <el-col :span="8" v-if="model.TYPE == 2">
              <div class="form-group">
                <label><b>单价</b></label>
                <div>
                  <el-input-number v-model="model.PRICE" :step="0.1" :precision="3" :min="0"
                    @change="changePrice"></el-input-number>
                </div>
              </div>
            </el-col>
            <el-col :span="8" v-if="model.TYPE == 2">
              <div class="form-group">
                <label><b>总价</b></label>
                <div>
                  <el-input-number v-model="model.ALLPRICE" :step="0.1" :precision="3" :min="0"
                    @change="changeAllPrice"></el-input-number>
                </div>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="8" v-if="model.TYPE == 1">
              <div class="form-group">
                <label><b>归属班级</b></label><br />
                <el-select v-model="model.CLASS_ID" :disabled="loc.val.length != 0" clearable>
                  <el-option v-for="item in ls_class" :key="item.ID" :label="item.NAME" :value="item.ID">
                  </el-option>
                </el-select>
              </div>
            </el-col>
            <el-col :span="16">
              <div class="form-group">
                <label><b>备注</b></label>
                <el-input type="text" v-model="model.DESCRIPTION" />
              </div>
            </el-col>
          </el-row>
        </div>
      </div>
      <div slot="footer" v-loading="sta.loading">
        <el-button icon="el-icon-close" @click="sta.show = false">取消</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit({show:false})">确认</el-button>
        <el-button icon="el-icon-check" type="success" @click="submit({show:true})">确认并继续</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>

  export default {
    data() {
      return {
        sta: {
          show: false,
          loading: false
        },
        model: { STOCK: {}, STORAGE_ID: "" },
        cat: {
          ls: [],
          all: [],
          val: []
        },
        store: [],
        ls_stock: [],
        ls_class: [],
        ls_teacher: [],
        loc: {
          ls: [],
          val: []
        },
        user: this.whale.user.get(),
        InOut_Stock_User: {}
      }
    },
    computed: {
      amount() {
        return this.model.PRICE * this.model.CNT;
      }
    },
    methods: {
      changePrice() {
        this.model.ALLPRICE = this.model.PRICE * this.model.CNT;
      },
      changeAllPrice() {
        this.model.PRICE = this.model.ALLPRICE / this.model.CNT;
      },
      init(tp) {
        let self = this;
        this.sta = { show: true, loading: false }
        this.model = { TYPE: tp, PRICE: 0, ALLPRICE: 0, CNT: 1, STOCK: {}, EVENT_DT: new Date(), HANDLE_NAME: this.user.NAME };
        this.InOut_Stock_User = { ID: this.user.ID, NAME: this.user.NAME }
        this.cat = { ls: [], all: this.$parent.cat.all, val: [] };
        this.store = this.$parent.store
        this.loc = { ls: this.$parent.loc.ls, val: [] };
        if (this.ls_class.length == 0) {
          this.whale.remote.getCollection({
            url: "/api/School/ORG/ClassApi/GetList",
            data: {},
            completed: function (its) {
              self.ls_class = its;
            }
          })
        }
        if (this.ls_teacher.length == 0) {
          this.whale.remote.getCollection({
            url: "/api/School/ORG/TeacherApi/GetList",
            data: {},
            completed: function (its) {
              self.ls_teacher = its;
            }
          })
        }
      },

      getStock(v) {
        let n = v.length;
        // console.log('getStock', v)
        if (n > 0) {
          this.model.CAT_ID = v[n - 1];
          let self = this;
          this.whale.remote.getCollection({
            url: "/api/School/MAT/StockApi/GetList",
            data: { CAT_ID: this.model.CAT_ID },
            completed: function (its, n) {
              self.model.ST_ID = "";
              self.ls_stock = its;
              if (n == 1) self.stockSel(its[0]);
            }
          })
        } else {
          this.model.STOCK = {}
        }
      },
      search(key) {
        let self = this
        this.whale.remote.getCollection({
          url: "/api/School/MAT/StockApi/GetList",
          data: { KEY: key },
          completed: function (its, n) {
            // self.model.ST_ID = "";
            self.ls_stock = its;
            // console.log('search', its, n)
            // if (n == 1) self.stockSel(its[0]);
          }
        })
      },
      stockChange(v) {
        // console.log(v, 'ls_stock', this.ls_stock)
        let self = this;
        this.ls_stock.forEach(it => {
          if (it.ID == v) {
            self.stockSel(it)
            // self.findCat(it)
            let val = self.catFind(it.CAT_ID, self.cat.ls);
            if (val == undefined) val = it.CAT_ID + '';
            else val += "," + it.CAT_ID;
            this.cat.val = val.split(',').map(o => {
              return parseInt(o)
            })
            // console.log(this.cat.val)
          }
        });
      },
      catFind(id, ls) {
        let n = ls.length;
        for (var i = 0; i < n; i++) {
          let it = ls[i]
          if (it.LEAF) {
            if (it.ID != id) continue;
            return it.ANCESTORS;
          } else {
            var ret = this.catFind(id, it.CHILDREN);
            if (ret != undefined) return ret;
          }
        }
      },
      stockSel(it) {
        this.model.STOCK = it;
        this.model.ST_ID = it.ID
        this.model.PRICE = it.PRICE
        this.model.STORAGE_ID = it.STORAGE_ID
        this.model.STORAGE_NAME = it.STORAGE_NAME
        this.model.ALLPRICE = it.PRICE * this.model.CNT;
        this.changeStorage()
      },
      submit({show}) {
        let self = this;
        self.sta.loading = true;
        let n = this.loc.val.length;
        if (n > 0) this.model.LOC_ID = this.loc.val[n - 1];
        let { MAN_USERS_LIST, MAN_USERS } = this.model.STOCK
        if (this.model.TYPE == 3) {
          this.model.MAN_USERS = MAN_USERS
          let arr = []
          if (MAN_USERS) {
            MAN_USERS_LIST.forEach(id => {
              let obj = this.ls_teacher.find(t => t.ID == id)
              if (obj) {
                arr.push(obj.NAME)
              }
            })
          }
          this.model.MAN_USERS_NAMES = arr.join(',')
        } else {
          this.model.InOut_Stock_User = this.InOut_Stock_User.NAME
        }
        if (!this.model.ST_ID) {
          this.$message.warning('请选择物品')
          self.sta.loading = false;
          return
        }
        if (this.model.TYPE == 3) {
          if (!this.model.HANDLE_NAME) {
            this.$message.warning('请选择盘存人')
            self.sta.loading = false;
            return
          }
        } else {
          if (!this.model.HANDLE_NAME) {
            this.$message.warning('请选择经手人')
            self.sta.loading = false;
            return
          }
        }
        // console.log(self.model)
        this.whale.remote.getResult({
          url: "/api/School/MAT/MBillApi/Save",
          data: self.model,
          finally() {
            self.sta.loading = false;
          },
          completed: function () {
            self.sta.show = show;
            if(show){
              self.$emit("rowEdit");
            }
            self.$emit("on-saved");
          }
        })
      },
      changeStorage() {
        if (this.model.STORAGE_ID > 0) {
          this.cat.ls = this.cat.all.filter(it => it.STORAGE_ID == this.model.STORAGE_ID);
        } else {
          this.ls_stock = []
          this.cat.ls = [];
        }
      },
    }
  }
</script>