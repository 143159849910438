<template>
  <div>
    <div>
      <div class="content-header container-fluid bg-white">
        <div class="row">
          <div class="col-sm-4">
            <h4>
              <i class="ti-shopping-cart-full"> </i> 物料管理 |
              <span v-if="grid.sea.TYPE == '1'">物品领用</span>
              <span v-else-if="grid.sea.TYPE == '2'">采购入库</span>
              <span v-else>仓库盘存</span>
            </h4>
          </div>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-md-12">
            <el-button type="primary" @click="rowEdit(grid.sea.TYPE)">新增单据</el-button>
            <el-button type="warning" @click="imp">导入单据</el-button>
            <!-- <form id="fm_export" method="post" action="/mat/bill/export" target="_blank" style="display: inline-block; margin: 0px 10px">
              <input type="hidden" name="KEY" v-model="grid.sea.KEY" />
              <input type="hidden" name="CAT_ID" v-model="grid.sea.CAT_ID" />
              <input type="hidden" name="TYPE" v-model="grid.sea.TYPE" />
              <input type="hidden" name="DTS" v-model="grid.sea.DTS" />
              <input type="hidden" name="DTE" v-model="grid.sea.DTE" /> -->
            <button type="submit" class="el-button el-button--success" @click="exportExcel">
              <span>导出Excel</span>
            </button>
            <!-- </form> -->
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="w-block" style="padding: 5px;">
              <el-select v-model="grid.sea.STORAGE_ID" @change="filter" placeholder="请选择仓库" clearable class="cxf">
                <el-option :label="it.STORAGE_NAME" :value="it.ID" v-for="it in store" :key="it.ID" />
              </el-select>
              <el-cascader v-model="cat.val" :options="cat.ls" placeholder="请选择分类" clearable class="cxf" @change="filter"
                :props="{ label: 'NAME', value: 'ID', children: 'CHILDREN', checkStrictly: true }" />

              <el-select v-model="grid.sea.OrderByType" @change="filter" clearable placeholder="请选择" class="cxf">
                <el-option label="倒序" :value="0" />
                <el-option label="正序" :value="1" />
              </el-select>
              <el-date-picker v-model="grid.date_range" @change="filter" type="daterange" value-format="yyyy-MM-dd" class="cxf"
                range-separator="-" start-placeholder="开始日期" end-placeholder="结束日期" style="margin-left: 5px" />
              <el-input v-model="grid.sea.KEY" placeholder="请输入关键字" style="width: 300px;margin-left: 5px" class="cxf">
                <el-button slot="append" icon="el-icon-search" @click="filter"></el-button>
              </el-input>
            </div>
            <wgrid :data="grid.ls" v-loading="grid.loading" :total="grid.total" :page-index="grid.sea.PAGE_INDEX + 1"
              @page-changed="loadPage">
              <el-table-column v-if="grid.sea.TYPE != 3" prop="TYPE_TXT" label="类型" width="100" align="center" />
              <el-table-column label="日期" width="100">
                <template slot-scope="scope" align="center">{{ scope.row.EVENT_DT | datetime('YYYY-MM-DD') }}</template>
              </el-table-column>
              <el-table-column prop="STORAGE_NAME" label="仓库名称" width="100" align="center" />
              <!--              <el-table-column v-if="grid.sea.TYPE == 1" prop="LOCA" label="位置" width="100" align="center" />-->
              <el-table-column prop="CAT_NAME" label="分类全称" width="200" align="center" />
              <el-table-column prop="ST_NAME" label="物品名称" width="200" />
              <el-table-column label="数量" width="100" align="center">
                <template slot-scope="scope">
                  {{ scope.row.CNTP }}
                  <!-- ({{ scope.row.UNIT }}) -->
                </template>
              </el-table-column>
              <el-table-column prop="UNIT" label="单位" width="100" align="center"></el-table-column>
              <el-table-column label="总金额(元)" width="100" align="right">
                <template slot-scope="scope">
                  ￥{{ scope.row.AMOUNTP.toFixed(2) }}
                </template>
              </el-table-column>
              <el-table-column prop="HANDLE_NAME" :label="grid.sea.TYPE == 3 ? '盘存人' : '经手人'" width="80"
                align="center" />
              <el-table-column v-if="grid.sea.TYPE == 1" label="所属" width="100" align="center">
                <template slot-scope="scope">
                  {{ scope.row.CLASS_NAME || scope.row.LOCA }}
                </template>
              </el-table-column>
              <el-table-column v-if="grid.sea.TYPE == 1" prop="InOut_Stock_User" label="出库人" width="80"
                align="center" />
              <el-table-column v-if="grid.sea.TYPE == 2" prop="InOut_Stock_User" label="入库人" width="80"
                align="center" />
              <el-table-column v-if="grid.sea.TYPE == 3" prop="MAN_USERS_NAMES" label="库管" width="80" align="center" />
              <!--                            <el-table-column prop="SN" label="单号" width="100" align="center" />-->
              <el-table-column prop="DESCRIPTION" label="备注" width="100" />
              <el-table-column label="操作" min-width="200">
                <template slot-scope="scope">
                  <el-button v-if="scope.row.TYPE == 2 && scope.row.SUBTYPE == 1" type="text" icon="el-icon-edit"
                    @click="copyData(scope.row)">复制</el-button>

                  <el-button v-if="scope.row.TYPE == 2 && scope.row.SUBTYPE == 1" type="text" icon="el-icon-delete"
                    @click="delData(scope.row)">作废</el-button>
                </template>
              </el-table-column>
            </wgrid>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Edit ref="bill_edit" @on-saved="filter" @rowEdit="rowEdit(grid.sea.TYPE)"/>
    </div>
    <div>
      <IMP ref="bill_imp" :type="grid.sea.TYPE" @on-saved="filter" />
    </div>
  </div>
</template>
<style>
  .el-radio {
    padding-top: 10px
  }
</style>
<script>
  import Edit from "./edit";
  import Wgrid from "@/components/wgrid";
  import IMP from './imp'
  import jsFileDownload from 'js-file-download'
  export default {
    name: "index",
    components: { Wgrid, Edit, IMP },
    data() {
      return {
        grid: {
          sea: {
            PAGE_SIZE: 12,
            PAGE_INDEX: 0,
            TYPE: this.$route.params.tp,
            OrderByType: 0,
            STORAGE_ID: 0
          },
          ls: [],
          total: 0,
          loading: false
        },
        cat: {
          ls: [],
          all: [],
          val: []
        },
        store: [],
        loc: {
          ls: [],
          val: []
        }
      }
    },
    created() {
      this.getCat();
      // this.getList();
      this.getLoc()
    },
    methods: {
      filter() {
        if (this.grid.sea.STORAGE_ID > 0) {
          this.cat.ls = this.cat.all.filter(it => it.STORAGE_ID == this.grid.sea.STORAGE_ID)
        } else
          this.cat.ls = []
        if (this.grid.date_range != null && this.grid.date_range.length > 1) {
          this.grid.sea.DTS = this.grid.date_range[0];
          this.grid.sea.DTE = this.grid.date_range[1];
        } else
          Object.assign(this.grid.sea, { DTS: '', DTE: '' })
        let n = this.cat.val.length;
        this.grid.sea.CAT_ID = n > 0 ? this.cat.val[n - 1] : 0;
        this.grid.sea.PAGE_INDEX = 0;
        this.grid.ls = [];
        this.getList();
      },
      loadPage(pi) {
        this.grid.sea.PAGE_INDEX = pi - 1;
        this.getList();
      },
      getList() {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getCollection({
          url: "/api/School/MAT/MBillApi/GetList",
          data: this.grid.sea,
          completed: function (its, n) {
            self.grid.ls = its;
            self.grid.total = n;
            self.grid.loading = false;
          }
        })
      },
      getCat() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MCatApi/GetTree",
          completed: function (its) {
            self.cat.all = its.DATA;
            self.getStore();
          }
        })
      },
      getStore() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MatStorage/GetList",
          completed: function (its) {
            self.store = its.DATA;
            if (its.DATA.length > 0) {
              self.grid.sea.STORAGE_ID = self.store[0].ID;
              self.cat.ls = self.cat.all.filter(it => it.STORAGE_ID == self.grid.sea.STORAGE_ID)
            }
            self.filter();
          }
        })
      },
      copyData(row) {
        let self = this;
        this.grid.loading = true;
        this.whale.remote.getResult({
          url: "/api/School/MAT/MBillApi/Copy",
          data: { ID: row.ID },
          finally() {
            self.grid.loading = false;
          },
          completed: function () {
            self.grid.loading = false;
            self.getList();
          }
        })
      },
      delData(row) {
        let self = this;
        this.whale.confirmText('你确定要作废此次采购吗？', () => {
          self.grid.loading = true;
          self.whale.remote.getResult({
            url: "/api/School/MAT/MBillApi/DelData",
            data: { ID: row.ID },
            finally() {
              self.grid.loading = false;
            },
            completed: function () {
              self.grid.loading = false;
              self.getList();
            }
          })
        });
      },
      getLoc() {
        let self = this;
        this.whale.remote.getResult({
          url: "/api/School/FIX/LocationApi/GetTree",
          completed: function (its) {
            self.loc.ls = its.DATA;
          }
        })
      },
      rowEdit(r) {
        this.$refs.bill_edit.init(r);
      },
      imp() {
        this.$refs.bill_imp.init(this.$route.params.tp);
      },
      exportExcel() {
        let self = this;
        this.grid.loading = true;
        const formData = new FormData()
        formData.append('KEY', this.grid.sea.KEY || '')
        formData.append('CAT_ID', this.grid.sea.CAT_ID || '')
        formData.append('TYPE', this.grid.sea.TYPE || '')
        formData.append('DTS', this.grid.sea.DTS || '')
        formData.append('DTE', this.grid.sea.DTE || '')
        formData.append('OrderByType', this.grid.sea.OrderByType || 0)
        this.whale.remote.getExport({
          url: "/api/School/MAT/MBill/Export",
          data: formData,
          completed: function (its) {
            // console.log('exls', its.Data)
            // self.whale.downLoad(its.Data)
            if (self.grid.sea.TYPE == '1') {
              jsFileDownload(its, '导出物料管理物品领用.xls')
            }
            else if (self.grid.sea.TYPE == '2') {
              jsFileDownload(its, '导出物料管理采购入库.xls')
            }
            else {
              jsFileDownload(its, '导出物料管理仓库盘存.xls')
            }
            self.grid.loading = false;
          }
        })
      }
    }
  }
</script>

<style lang="less" scoped>
  .cxf {
    margin-left: 5px;
  }
</style>